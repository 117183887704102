<template>
  <div>
    <el-form>
      <div class="title">基本信息</div>
      <el-row :gutter="24">
        <el-col :md="6">
          <el-form-item label="终端编码" class="flex">
            <el-input readonly v-model="value.clientCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item label="终端名称" class="flex">
            <el-input readonly v-model="value.clientName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item label="客户分类" class="flex">
            <el-input readonly v-model="value.clientTypeDescribe"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item label="提交人登录账号" class="flex">
            <el-input readonly v-model="value.createCode"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="6">
          <el-form-item label="提交人姓名" class="flex">
            <el-input readonly v-model="value.createName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item label="提交人职位名称" class="flex">
            <el-input readonly v-model="value.createPosName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item label="终端所属区域" class="flex">
            <el-input readonly v-model="value.clientArea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item label="终端所属办事处" class="flex">
            <el-input readonly v-model="value.clientOffice"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="6">
          <el-form-item label="提交时间" class="flex">
            <el-input readonly v-model="value.createDate"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="title">竞品信息</div>
      <el-row :gutter="24" class="mb-20">
        <el-col :md="24">
          <vxe-table :data="value.itemRespVos" :seq-config="{startIndex: 0}">
            <vxe-table-column type="seq" title="序号"></vxe-table-column>
            <vxe-table-column field="brand" title="所属品牌"></vxe-table-column>
            <vxe-table-column field="series" title="所属系列"></vxe-table-column>
            <vxe-table-column field="productName" title="竞品名称"></vxe-table-column>
            <vxe-table-column field="quantity" title="铺货数量"></vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>
      <div class="title">活动信息</div>
      <el-row :gutter="24" class="mb-20">
        <el-col :md="24">
          <el-input readonly v-model="value.activityInfo" type="textarea"></el-input>
        </el-col>
      </el-row>
      <!-- <div>活动信息：{{value.activityName}}</div> -->
      <div class="title">图片</div>
      <el-row :gutter="24" class="fileImg">
        <el-col :md="24">
          <el-image
            :src="item"
            @click.stop="handleClickItem"
            :preview-src-list="pictureList"
            v-for="(item, index) in pictureList"
            :key="index"
          ></el-image>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      pictureList: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        console.log(val);
        if (val) {
          this.pictureList = val.imgList;
        }
      },
    },
  },
  created() {
    this.$emit('input', this.value);
  },
  methods: {
    handleClickItem() {
      // 获取遮罩层dom
      this.$nextTick(() => {
        const domImageMask = document.querySelector('.el-image-viewer__mask');
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener('click', () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          // document.querySelector('.el-image-viewer__close').click();
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.title{
  font-size: 18px;
  margin-bottom: 20px;
}
.el-row {
  margin: 0 !important;
}
.mb-20{
  margin-bottom: 20px !important;
}
/deep/ .el-form-item__label {
  width: 125px;
}
/deep/ .el-form-item__content {
  flex: 1;
}
/deep/ .el-textarea__inner {
  height: 80px;
}
.el-col {
  padding: 0 !important;
}
/deep/.el-image__inner ,
/deep/ .el-image__error{
  width: 280px;
  height: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
}
/deep/ .fileImg .el-form-item__content > div{
  width: auto;
}
</style>
