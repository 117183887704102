<script>
import TablePage from '../../../../../components/table_page';
import Form from './form';
// import ExportModal from '../../../../../components/export_modal';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
    // ExportModal,
  },
  data() {
    return {
      requestUrl: '/sfa/sfavisitstepcolletproduct/reportFormsEsList',
      paramsProps: {
        stepCode: 'sfa_step_code_competitor',
      },
      formConfig: {},
      modalConfig: {
        title: '查看',
        visible: false,
        type: '',
      },
      sid: '',
      // modalName: '',
    };
  },
  created() {
    this.getConfigList('competitor_product_info');
  },
  methods: {
    // beforeModalClick() {
    //   this.modalName = '';
    // },
    // modalClick({ val, row }) {
    //   console.log(row);
    //   if (val.code === 'export') {
    //     this.modalName = 'ExportModal';
    //     this.propsObjInData = {
    //       functionCode: 'competitor_product_info',
    //       requestUrl: '/sfa/sfavisitstepcolletproduct/reportFormsList',
    //       visible: true,
    //     };
    //     console.log(this.propsObjInData);
    //   }
    // },
    cellClick({ row, column }) {
      if (column.property === 'clientCode') {
        this.formConfig = {
          type: 'view',
          id: row.id,
        };
        this.openFull();
      }
    },
  },
};
</script>
