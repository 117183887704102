<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import formAdd from './components';

formCreate.component('formAdd', formAdd);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      buttons: {
        submit: false,
      },
    };
  },
  async created() {
    const rule = await this.getFormRule('competitor_product_info_detail');
    const newRule = [];
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'customize') {
        newRule.push({
          type: 'formAdd',
          field: 'customize',
          title: '',
          value: {},
        });
      }
    });
    this.rule = newRule;
    this.reload(this.rule);
    request
      .get('/sfa/sfavisitstepcolletproduct/queryReportFormsEs', {
        id: this.formConfig.id,
      })
      .then((res) => {
        if (res.success) {
          const { result } = res;
          result.imgList = result.sfaVisitPictureReqVos.map((item) => item.urlPathPrefix + item.urlPath);
          this.setValue({ customize: result });
        }
      });
  },

  methods: {
    // 提交
    submit() {
      this.$emit('onClose');
    },
  },
};
</script>
