var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "终端编码" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.clientCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "clientCode", $$v)
                          },
                          expression: "value.clientCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "终端名称" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.clientName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "clientName", $$v)
                          },
                          expression: "value.clientName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "客户分类" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.clientTypeDescribe,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "clientTypeDescribe", $$v)
                          },
                          expression: "value.clientTypeDescribe",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "提交人登录账号" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.createCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "createCode", $$v)
                          },
                          expression: "value.createCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "提交人姓名" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.createName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "createName", $$v)
                          },
                          expression: "value.createName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "提交人职位名称" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.createPosName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "createPosName", $$v)
                          },
                          expression: "value.createPosName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "终端所属区域" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.clientArea,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "clientArea", $$v)
                          },
                          expression: "value.clientArea",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "终端所属办事处" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.clientOffice,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "clientOffice", $$v)
                          },
                          expression: "value.clientOffice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "提交时间" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.value.createDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "createDate", $$v)
                          },
                          expression: "value.createDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("竞品信息")]),
          _c(
            "el-row",
            { staticClass: "mb-20", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "vxe-table",
                    {
                      attrs: {
                        data: _vm.value.itemRespVos,
                        "seq-config": { startIndex: 0 },
                      },
                    },
                    [
                      _c("vxe-table-column", {
                        attrs: { type: "seq", title: "序号" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { field: "brand", title: "所属品牌" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { field: "series", title: "所属系列" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { field: "productName", title: "竞品名称" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { field: "quantity", title: "铺货数量" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("活动信息")]),
          _c(
            "el-row",
            { staticClass: "mb-20", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c("el-input", {
                    attrs: { readonly: "", type: "textarea" },
                    model: {
                      value: _vm.value.activityInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "activityInfo", $$v)
                      },
                      expression: "value.activityInfo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("图片")]),
          _c(
            "el-row",
            { staticClass: "fileImg", attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                _vm._l(_vm.pictureList, function (item, index) {
                  return _c("el-image", {
                    key: index,
                    attrs: { src: item, "preview-src-list": _vm.pictureList },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleClickItem.apply(null, arguments)
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }